import React, { useState, useEffect } from "react";
import { useWeb3Modal } from "@web3modal/react";
import { useAccount, useDisconnect, useNetwork } from "wagmi";
import Web3 from "web3";
import { trimAddress } from "../hooks/constant";
import { toast } from "react-toastify";

export default function Connect() {
  const { open } = useWeb3Modal();
  const { chain } = useNetwork();
  const { isConnected, address } = useAccount();
  const { disconnect } = useDisconnect();
  const [chainId, setChainId] = useState(null);
  const [networkLogo, setNetworkLogo] = useState(null);
  const [networkName, setNetworkName] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 575);
  const [isSupportedNetwork, setIsSupportedNetwork] = useState(true);
  const [toastCooldown, setToastCooldown] = useState(false); 
  const web3 = new Web3(window.ethereum);

  const TESTNET_CHAIN_ID = 24116;
  const MAINNET_CHAIN_ID = 6278;
  const BSCTESTNET_CHAIN_ID = 97;

  async function onOpen() {
    await open();
  }

  async function switchToNetwork(networkId) {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `0x${networkId.toString(16)}` }],
      });
      setDropdownOpen(false); 
      updateNetworkInfo(networkId);
    } catch (error) {
      console.error("Error switching network:", error);
      if (!toastCooldown) {
        toast.error("Failed to switch network.");
        setToastCooldown(true); 
        setTimeout(() => setToastCooldown(false), 3000); 
      }
    }
  }

  function updateNetworkInfo(id) {
    setChainId(id);
    if (id === TESTNET_CHAIN_ID) {
      setNetworkLogo(require('../images/testnet.png'));
      setNetworkName('Rails Testnet');
      setIsSupportedNetwork(true);
    } else if (id === MAINNET_CHAIN_ID) {
      setNetworkLogo(require('../images/mainnet.png'));
      setNetworkName('Rails Mainnet');
      setIsSupportedNetwork(true);
    } else if (id === BSCTESTNET_CHAIN_ID) {
      setNetworkLogo(require('../images/testnet.svg').default);
      setNetworkName('BSC Testnet');
      setIsSupportedNetwork(false);
      if (!toastCooldown) {
        toast.error("Unsupported network. Please switch to Rails Mainnet or Testnet.");
        setToastCooldown(true);
        setTimeout(() => setToastCooldown(false), 3000); 
      }
    } else {
      setNetworkLogo(null);
      setNetworkName('Unknown Network');
      setIsSupportedNetwork(false);
      if (!toastCooldown) {
        toast.error("Unsupported network. Please switch to Rails Mainnet or Testnet.");
        setToastCooldown(true); 
        setTimeout(() => setToastCooldown(false), 3000); 
      }
    }
  }

  async function getChainId() {
    try {
      const id = chain?.id;
      updateNetworkInfo(id);
    } catch (error) {
      console.error("Error fetching chain ID:", error);
    }
  }

  function onClick() {
    if (isConnected) {
      setDropdownOpen(!dropdownOpen);
    } else {
      onOpen();
    }
  }

  useEffect(() => {
    getChainId();
    
    window.ethereum.on('chainChanged', (chainIdHex) => {
      const newChainId = parseInt(chainIdHex, 16);
      updateNetworkInfo(newChainId);
      window.location.reload();
    });
  
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 575);
    };
  
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.ethereum.removeListener('chainChanged', updateNetworkInfo);
      window.removeEventListener('resize', handleResize);
    };
  }, [address]);
  

  
  useEffect(() => {
    setToastCooldown(false);
  }, [chainId]);

  return (
    <React.Fragment>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          {networkLogo && (
            <img
              src={networkLogo}
              alt="Network Logo"
              width="50px"
              style={{ cursor: 'pointer' }}
              className="show-on-mobile"
              onClick={() => setDropdownOpen(!dropdownOpen)}
            />
          )}
          {!isMobile && networkLogo && (
            <>
              <img
                src={networkLogo}
                alt="Network Logo"
                className="mr-2"
                width="50px"
              />
              <span style={{ fontSize: '1.2rem', fontWeight: 'bold', marginLeft: '10px' }}>{networkName}</span>
            </>
          )}
        </div>
        <div className="dropdown">
          <button
            type="button"
            className="btn"
            onClick={onClick}
          >
            {isConnected ? trimAddress(address) : 'Connect Wallet'}
          </button>
          {isConnected && dropdownOpen && (
            <div
              className={`dropdown-menu ${isMobile ? 'mobile-dropdown' : 'show'}`}
              style={{
                display: 'block',
                position: 'absolute',
                top: '100%',
                right: '0',
                left: 'auto',
                width: 'auto',
                backgroundColor: '#1f4a7d',
                color: '#fafafa',
                zIndex: 1000,
                overflowY: 'visible',
              }}
            >
              <button
                className="dropdown-item d-flex align-items-center"
                onClick={() => switchToNetwork(MAINNET_CHAIN_ID)}
                style={{ backgroundColor: '#1f4a7d', color: '#fafafa' }}
              >
                <img
                  src={require('../images/mainnet.png')}
                  alt="Mainnet Logo"
                  width="20px"
                  className="mr-2"
                />
                Rails Mainnet
              </button>
              <button
                className="dropdown-item d-flex align-items-center"
                onClick={() => switchToNetwork(TESTNET_CHAIN_ID)}
                style={{ backgroundColor: '#1f4a7d', color: '#fafafa' }}
              >
                <img
                  src={require('../images/testnet.png')}
                  alt="Testnet Logo"
                  width="20px"
                  className="mr-2"
                />
                Rails Testnet
              </button>
              <div className="dropdown-divider"></div>
              <button
                className="dropdown-item"
                onClick={disconnect}
                style={{ backgroundColor: '#1f4a7d', color: '#fafafa' }}
              >
                Disconnect Wallet
              </button>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
