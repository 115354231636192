export const trimAddress = (addr) => {
  return `${addr.substring(0, 6)}...${addr.substring(addr.length - 4)}`;
};

//Launchpad Contract

export const contract = {
  24116: {
    poolfactory: "0x0EfBc2a57E166610747Bdd2aC822C831Fc65b524",
    routeraddress: "0x5BEf7016846eAA8424668683D2eB2f5e9CF822e9",
    poolmanager: "0x1b06f3382B469d9781B776BB260B5C6E53b62764",
    multicallAddress: "0xe381212B758A446F5c85A1b37278460622552837",
    lockAddress: "0x10CAE6C3fEBcF8c51FdE6FfB57EfF1e0ed35A825",
    routername: "RailsDEX",
    feeReceiver: "0x76e6a92022bFC3F645419bd776429a8Da1041337",
    dividendTracker: "",
  },
  6278: {
    poolfactory: "0xEF3546F9C328BF47866A8365dA1566b95F4abFef",
    routeraddress: "0x652fe694E94FA129bc96930171EeE2333B44F38d",
    poolmanager: "0x47ABb045C42b8D1094717cb10eD4c5641075FC7f",
    multicallAddress: "0x72A34115BC568e9d8d0c71e277B19e75015b3e88",
    lockAddress: "0xC1a476CC85Ee757552a385eE041842eAF88adB98",
    routername: "RailsDEX",
    feeReceiver: "0x76e6a92022bFC3F645419bd776429a8Da1041337",
    dividendTracker: "",
  },
  97: {
    poolfactory: "0xA74dbaa34A4ACF801A49326b7588F0A7c90eaBCe",
    routeraddress: "0xD99D1c33F9fC3444f8101754aBC46c52416550D1",
    poolmanager: "0x1d772eaA50175a4Abb0Be95966b322629DB72DfB",
    multicallAddress: "0x6e5BB1a5Ad6F68A8D7D6A5e47750eC15773d6042",
    lockAddress: "0x0318C81d3D42560c3E2d8Dbcd62F5FdBcAEd8A60",
    routername: "PancakeSwap",
    feeReceiver: "0x76e6a92022bFC3F645419bd776429a8Da1041337",
    dividendTracker: "",
  },
  default: {
    poolfactory: "0xEF3546F9C328BF47866A8365dA1566b95F4abFef",
    routeraddress: "0x652fe694E94FA129bc96930171EeE2333B44F38d",
    poolmanager: "0x47ABb045C42b8D1094717cb10eD4c5641075FC7f",
    multicallAddress: "0x72A34115BC568e9d8d0c71e277B19e75015b3e88",
    lockAddress: "0xC1a476CC85Ee757552a385eE041842eAF88adB98",
    routername: "RailsDEX",
    feeReceiver: "0x76e6a92022bFC3F645419bd776429a8Da1041337",
    dividendTracker: "",
  },
};
