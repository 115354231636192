import { useEffect, useState } from "react"
// import { useWeb3React } from "@web3-react/core";
import { MulticallContractWeb3 } from "../../../../hooks/useContracts";
import { getWeb3 } from "../../../../hooks/connectors";
import poolFactoryAbi from '../../../../json/PoolFactory.json';
import { toast } from "react-toastify";
import { contract } from "../../../../hooks/constant";
import { useHistory } from "react-router-dom";
import { useNetwork } from "wagmi";

export const useCommonStats = (updater) => {
  // const context = useWeb3React();
  // const { chainId } = context;
  const { chain, chains } = useNetwork()
  let history = useHistory();

  let web3 = getWeb3(chain?.id);
  let poolFactoryAddress = contract[chain?.id] ? contract[chain?.id].poolfactory : contract['default'].poolfactory


  const [stats, setStats] = useState({
    poolPrice: 0,
    // auditPrice: 0,
    // kycPrice: 0
  });

  const mc = MulticallContractWeb3(chain?.id);
  let pmc = new web3.eth.Contract(poolFactoryAbi, poolFactoryAddress);
  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await mc.aggregate([
          pmc.methods.masterPrice()
          // pmc.methods.auditPrice(),
          // pmc.methods.kycPrice(),
        ]);

        setStats({
          poolPrice: data[0] / Math.pow(10, 18)
          // auditPrice: data[1] / Math.pow(10, 18),
          // kycPrice: data[2] / Math.pow(10, 18)
        })
      }
      catch (err) {
        toast.error(err.reason ? err.reason : err.message);
        // history.push('/sale-list');
      }
    }

    if (mc) {
      fetch();
    }
    else {
      setStats({
        poolPrice: 0,
        // auditPrice: 0,
        // kycPrice: 0
      })
    }
    // eslint-disable-next-line
  }, [updater, chain?.id]);

  return stats;
}