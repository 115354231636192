import chainlogo from "../images/rails.png";

export const supportNetwork = {
  24116: {
    name: "Rails Testnet",
    chainId: 24116,
    rpc: "https://testnet.steamexchange.io",
    image: chainlogo,
    symbol: "STEAMX",
    subgraph: "https://testgraph.steamexchange.io/subgraphs/name/rails-launchpad/",
  },
  6278: {
    name: "Rails Mainnet",
    chainId: 6278,
    rpc: "https://mainnet.steamexchange.io",
    image: chainlogo,
    symbol: "STEAMX",
    subgraph: "https://graph.steamexchange.io/subgraphs/name/rails-launchpad/",
  },
  default: {
    name: "Rails Mainnet",
    chainId: 6278,
    rpc: "https://mainnet.steamexchange.io",
    image: chainlogo,
    symbol: "STEAMX",
    subgraph: "https://graph.steamexchange.io/subgraphs/name/rails-launchpad/",
  },
};

export const RPC_URLS = {
  24116: "https://testnet.steamexchange.io",
  6278: "https://mainnet.steamexchange.io"
};

export const getChainId = (queryChainId, chainId) => {
  return supportNetwork[queryChainId || chainId]?.chainId || supportNetwork["default"].chainId;
};

export const getSubgraphUrl = (chainId) => {
  return supportNetwork[chainId]?.subgraph || supportNetwork["default"].subgraph;
};
