import React, { useState } from "react";
import { Link } from "react-router-dom";
import { usePadStatus } from "./helper/useStats";

import logo from "../../images/logo.png";
import connectlogo from "../../images/ic1.png"
import checklogo from "../../images/ic2.png"
import locklogo from "../../images/ic3.png"

export default function Home() {
  const stats = usePadStatus();

  return (
    <React.Fragment>
      <section id="home" className="pt-0 project-area">
        <div className="container px-2 py-5 px-sm-5">
          <div className="mt-5 text-center">
            <h3 className="text-uppercase">
              Rails Network: Launchpad and Innovation Lab
            </h3>
            <p>
            Empowering Vetted Project Owners and Developers with Presale Creation, Token Creation, and Secure Rails: Locker Services (LP Locking, Token Locking, Token Vesting)
            </p>
          </div>
          <div className="mt-5 text-center">
            <Link to="/presale">
              <button className="btn">Create Presale</button>
            </Link>
            <a href="https://docs.steamexchange.io/" target="_blank">
              <button className="btn-negative">Learn more</button>
            </a>
          </div>
          <div className="my-5 row">
            <div className="col-12 col-md-6 col-xl-3">
              <div className="pt-0 pb-4 my-2 text-center card-header">
                <h3>${(stats.totalLiquidityRaised).toFixed(2)}</h3>
                <p className="">Liquidity Raised</p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-3">
              <div className="pt-0 pb-4 my-2 text-center card-header">
                <h3>{stats.totalProjects}</h3>
                <p>Total Projects Launched</p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-3">
              <div className="pt-0 pb-4 my-2 text-center card-header">
                <h3>{stats.totalParticipants}</h3>
                <p>Total Participants</p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-3">
              <div className="pt-0 pb-4 my-2 text-center card-header">
                <h3>${(stats.totalValueLocked).toFixed(2)}</h3>
                <p>Total Value Locked (TVL)</p>
              </div>
            </div>
          </div>
          <div className="mt-5 text-center d-flex flex-column justify-content-center align-items-center">
            <h3 className="text-center text-uppercase col-12 col-md-10">
              Available Tools:
            </h3>
            <p className="text-center col-12 col-md-10">
            Our comprehensive suite of tools allows vetted project owners and developers to create their own tokens and presales quickly, easily, and affordably. Enjoy cost-effective token creation and locking options, all without any coding knowledge required and fully decentralized!
            </p>
          </div>
          <div className="my-5 row" style={{ justifyContent: "center" }}>
  <div className="col-12 col-md-6 col-xl-3">
    <div className="pt-4 pb-4 text-center card-main d-flex flex-column justify-content-center align-items-center" style={{ height: "100%" }}>
      <img src={connectlogo} alt="Brand Logo" width="150px" />
      <h4 className="my-2">Launchpad</h4>
      <p>
        Vetted project owners and developers can create a presale for their newly created token.
      </p>
    </div>
  </div>
  
  <div className="col-12 col-md-6 col-xl-3">
    <div className="pt-4 pb-4 text-center card-main d-flex flex-column justify-content-center align-items-center" style={{ height: "100%" }}>
      <img src={checklogo} alt="Brand Logo" width="150px" />
      <h4 className="my-2">Innovation Lab</h4>
      <p>
        The most disruptive projects will become "Incubated Projects". Backed by Rails Network.
      </p>
    </div>
  </div>

  <div className="col-12 col-md-6 col-xl-3">
    <div className="pt-4 pb-4 text-center card-main d-flex flex-column justify-content-center align-items-center" style={{ height: "100%" }}>
      <img src={locklogo} alt="Brand Logo" width="150px" />
      <h4 className="my-2">Rails Locker</h4>
      <p>
        Lock LP tokens, tokens, and create vesting schedules with Rails: Locker.
      </p>
    </div>
  </div>


            
          </div>
          <div className="row">
            <p className="text-center">
              Disclaimer: The information provided shall not in any way
              constitute a recommendation as to whether you should invest in any
              product discussed. We accept no liability for any loss occasioned
              to any person acting or refraining from action as a result of any
              material provided or published.
            </p>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
