import React, { useState } from "react";
import Countdown, { zeroPad } from "react-countdown";
import { formatPrice, getWeb3Contract } from "../../hooks/contractHelper";
import { useRecordStats } from "./helper/useStats";
import Button from "react-bootstrap-button-loader";
import { toast } from "react-toastify";
import { contract } from "../../hooks/constant";
import { useAccount, useNetwork } from "wagmi";
import { Link } from "react-router-dom";
import lockAbi from "../../json/RailsLocker.json";
import { getWeb3 } from "../../hooks/connectors";

export default function LockRecord() {
  const { address } = useAccount();
  const chain = useNetwork();
  const [updater, setUpdater] = useState(new Date());
  const stats = useRecordStats(updater);
  const [ctLoading, setCtLoading] = useState(false);

  // Set the explorer link based on the current chain ID
  const explorerLink =
    chain?.chain?.id === 24116
      ? "https://build.steamexchange.io/address/"
      : chain?.chain?.id === 6278
      ? "https://explore.steamexchange.io/address/"
      : "https://build.steamexchange.io/address/"; // Default fallback if chain ID is not recognized

  const countdownrender = ({ days, hours, minutes, seconds, completed }) => {
    const numberStyle = { color: 'white' };
    const letterStyle = { color: '#d28a1e', marginLeft: '2px' };
    const listStyle = { display: 'flex', alignItems: 'center', margin: '0 5px' };
  
    if (completed) {
      return (
        <ul style={{ listStyleType: 'none', padding: 0, margin: 0, display: 'flex', justifyContent: 'center' }}>
          <li style={listStyle}>
            <span style={letterStyle}>&nbsp;D&nbsp;:&nbsp;</span>
            <span style={numberStyle}>00</span>
          </li>
          <li style={listStyle}>
            <span style={letterStyle}>&nbsp;H&nbsp;:&nbsp;</span>
            <span style={numberStyle}>00</span>
          </li>
          <li style={listStyle}>
            <span style={letterStyle}>&nbsp;M&nbsp;:&nbsp;</span>
            <span style={numberStyle}>00</span>
          </li>
          <li style={listStyle}>
            <span style={letterStyle}>&nbsp;S&nbsp;:&nbsp;</span>
            <span style={numberStyle}>00</span>
          </li>
        </ul>
      );
    } else {
      return (
        <ul style={{ listStyleType: 'none', padding: 0, margin: 0, display: 'flex', justifyContent: 'center' }}>
          <li style={listStyle}>
            <span style={letterStyle}>&nbsp;D&nbsp;:&nbsp;</span>
            <span style={numberStyle}>{zeroPad(days, 2)}</span>
          </li>
          <li style={listStyle}>
            <span style={letterStyle}>&nbsp;H&nbsp;:&nbsp;</span>
            <span style={numberStyle}>{zeroPad(hours, 2)}</span>
          </li>
          <li style={listStyle}>
            <span style={letterStyle}>&nbsp;M&nbsp;:&nbsp;</span>
            <span style={numberStyle}>{zeroPad(minutes, 2)}</span>
          </li>
          <li style={listStyle}>
            <span style={letterStyle}>&nbsp;S&nbsp;:&nbsp;</span>
            <span style={numberStyle}>{zeroPad(seconds, 2)}</span>
          </li>
        </ul>
      );
    }
  };

  const handleUnlock = async (e) => {
    e.preventDefault();
    setCtLoading(true);
    try {
      if (address) {
        let lockAddress = contract[chain?.id]
          ? contract[chain?.id].lockAddress
          : contract["default"].lockAddress;
        let lockContract = getWeb3Contract(lockAbi, lockAddress);

        let tx = await lockContract.methods.unlock(stats.id).send(
          { from: address }
        );
        const resolveAfter3Sec = new Promise((resolve) =>
          setTimeout(resolve, 5000)
        );
        toast.promise(resolveAfter3Sec, {
          pending: "Waiting for confirmation",
        });

        var interval = setInterval(async function () {
          let web3 = getWeb3(chain?.id);
          var response = await web3.eth.getTransactionReceipt(tx.transactionHash);
          if (response != null) {
            clearInterval(interval);
            if (response.status === true) {
              toast.success("success! your last transaction was successful 👍");
              setUpdater(new Date());
              setCtLoading(false);
            } else if (response.status === false) {
              toast.error("error ! Your last transaction failed.");
              setUpdater(new Date());
              setCtLoading(false);
            } else {
              toast.error("error ! something went wrong.");
              setUpdater(new Date());
              setCtLoading(false);
            }
          }
        }, 5000);
      } else {
        toast.error("Please Connect your wallet !");
        setCtLoading(false);
      }
    } catch (err) {
      toast.error(err.reason ? err.reason : err.message);
      setCtLoading(false);
    }
  };

  // Calculate the next cycle unlock date
  const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
  const timeElapsedSinceTGE = currentTime - stats.tgeDate;

  const nextCycleUnlockDate = timeElapsedSinceTGE > 0 && stats.cycle > 0
    ? currentTime + (stats.cycle - (timeElapsedSinceTGE % stats.cycle))
    : stats.tgeDate; // If TGE hasn't started or cycle is zero, fall back to TGE date

  return (
    <div className="container detail-page">
      <React.Fragment>
        <section className="explore-area prev-project-area">
          <div className="igo-rankging-table-list pb-140 md-pb-60">
            <div className="row justify-content-center">
              <div className="col-md-10 col-12">
                <div className="m-0 my-2 card project-card m-sm-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="mt-4 col-12">
                        <div className="d-flex justify-content-center">
                          <p style={{ fontSize: '1.50rem' }}>Assets Unlock In</p>
                        </div>
                        <div className="price-counter-new d-flex justify-content-center">
                          <div className="countdown">
                            <Countdown
                              key={Math.floor(Math.random() * 10 + 1)}
                              date={stats.tgeDate * 1000}
                              renderer={countdownrender}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-10 col-12">
                <div className="m-0 my-2 card project-card m-sm-3">
                  <h4 className="ml-3 card-title">Token Info</h4>
                  <div className="card-body">
                    <div className="mt-3 d-flex justify-content-between flex-column flex-sm-row card-span">
                      <span>Token Address</span>
                      <a className="step-input-value" href={explorerLink + stats.TokenAddress} target="_blank" rel="noopener noreferrer" style={{ cursor: "pointer" }}>{stats.TokenAddress}</a>
                    </div>
                    <div className="mt-3 d-flex justify-content-between flex-column flex-sm-row card-span">
                      <span>Token Name</span>
                      <p className="step-input-value">{stats.TokenName}</p>
                    </div>
                    <div className="mt-3 d-flex justify-content-between flex-column flex-sm-row card-span">
                      <span>Token Symbol</span>
                      <p className="step-input-value">{stats.TokenSymbol}</p>
                    </div>
                    <div className="mt-3 d-flex justify-content-between flex-column flex-sm-row card-span">
                      <span>Token Decimals</span>
                      <p className="step-input-value">{stats.TokenDecimals}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-10 col-12">
                <div className="m-0 my-2 card project-card m-sm-3">
                  <h4 className="ml-3 card-title">Lock Info</h4>
                  <div className="card-body">
                    <div className="mt-3 d-flex justify-content-between flex-column flex-sm-row card-span">
                      <span>Title</span>
                      <p className="step-input-value">{stats.description}</p>
                    </div>
                    <div className="mt-3 d-flex justify-content-between flex-column flex-sm-row card-span">
                      <span>Total Amount Locked</span>
                      <p className="step-input-value">
                        {formatPrice(stats.amount)} {stats.TokenSymbol}
                      </p>
                    </div>
                    <div className="mt-3 d-flex justify-content-between flex-column flex-sm-row card-span">
                      <span>Owner</span>
                      <p className="step-input-value">{stats.owner}</p>
                    </div>
                    <div className="mt-3 d-flex justify-content-between flex-column flex-sm-row card-span">
                      <span>Lock Date</span>
                      <p className="step-input-value">
                        {stats.lockDate
                          ? new Date(stats.lockDate * 1000)
                            .toUTCString()
                            .substring(4, 25)
                          : "-"}{" "}
                        UTC
                      </p>
                    </div>
                    {stats.cycle <= 0 ? (
                      <div className="mt-3 d-flex justify-content-between flex-column flex-sm-row card-span">
                        <span>Unlock Date</span>
                        <p className="step-input-value">
                          {stats.tgeDate
                            ? new Date(stats.tgeDate * 1000)
                              .toUTCString()
                              .substring(4, 25)
                            : "-"}{" "}
                          UTC
                        </p>
                      </div>
                    ) : (
                      <React.Fragment>
                        <div className="mt-3 d-flex justify-content-between flex-column flex-sm-row card-span">
                          <span>Initial Asset Release Date</span>
                          <p className="step-input-value">
                            {stats.tgeDate
                              ? new Date(stats.tgeDate * 1000)
                                .toUTCString()
                                .substring(4, 25)
                              : "-"}{" "}
                            UTC
                          </p>
                        </div>
                        <div className="mt-3 d-flex justify-content-between flex-column flex-sm-row card-span">
                          <span>Initial % Released</span>
                          <p className="step-input-value">
                            {stats.tgeBps ? stats.tgeBps / 100 : 0}%
                          </p>
                        </div>
                        <div className="mt-3 d-flex justify-content-between flex-column flex-sm-row card-span">
                          <span>Cycle</span>
                          <p className="step-input-value">
                            {stats.cycle ? stats.cycle / 60 : 0} minutes
                          </p>
                        </div>
                        <div className="mt-3 d-flex justify-content-between flex-column flex-sm-row card-span">
                          <span>Cycle Release Percent</span>
                          <p className="step-input-value">
                            {stats.cycleBps ? stats.cycleBps / 100 : 0}%
                          </p>
                        </div>
                        <div className="mt-3 d-flex justify-content-between flex-column flex-sm-row card-span">
                          <span>Locked Assets Remaining</span>
                          <p className="step-input-value">
                            {formatPrice(stats.amount - stats.unlockedAmount)} {stats.TokenSymbol}
                          </p>
                        </div>
                   
                      </React.Fragment>
                    )}
                    <div className="mt-3 d-flex justify-content-between flex-column flex-sm-row card-span">
                      <span>Unlocked Amount</span>
                      <p className="step-input-value">
                        {stats.unlockedAmount ? stats.unlockedAmount : 0}{" "}
                        {stats.TokenSymbol}
                      </p>
                    </div>
                    {stats.cycle > 0 && (
                      <div className="mt-3 d-flex justify-content-center flex-column align-items-center">
                        <div className="d-flex justify-content-center">
                          <p style={{ fontSize: '1.50rem' }}>Next Cycle Unlocks In</p>
                        </div>
                        <div className="mt-2 price-counter-new d-flex justify-content-center">
                          <div className="countdown">
                            <Countdown
                              key={Math.floor(Math.random() * 10 + 1)}
                              date={nextCycleUnlockDate * 1000} // Corrected calculation for next cycle unlock
                              renderer={countdownrender}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    {address && String(address).toLowerCase() === stats.owner.toLowerCase() && (
                      <div className="mt-3 d-flex justify-content-center flex-column align-items-center">
                        <Button
                          variant="none"
                          loading={ctLoading}
                          className="btn"
                          onClick={(e) => handleUnlock(e)}
                        >
                          Unlock&nbsp;
                          {stats.withdrawableTokens > 0
                            ? `(${stats.withdrawableTokens} ${stats.TokenSymbol})`
                            : ""}
                        </Button>
                        {stats.cycle > 0 && (
                          <div className="mt-2 d-flex justify-content-center">
                            <p style={{ fontSize: '1rem' }}>
                              Cycles Remaining: {/* Calculate and display cycles remaining */}
                              {Math.floor((stats.amount - stats.unlockedAmount) / (stats.amount * (stats.cycleBps / 10000)))}
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    </div>
  );
}
