export const currencies = {
  24116: [
    { address: "0x0000000000000000000000000000000000000000", symbol: "STEAMX" },
  ],
  6278: [
    { address: "0x0000000000000000000000000000000000000000", symbol: "STEAMX" },
  ],
  97: [
    { address: "0x0000000000000000000000000000000000000000", symbol: "BNB" },
  ],
  default: [
    { address: "0x0000000000000000000000000000000000000000", symbol: "STEAMX" },
  ],
};
