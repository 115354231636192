import React, { useState } from "react";
import { Link } from "react-router-dom";
import HashLoader from "react-spinners/HashLoader";
import { usePoolListUser } from "../helper/useStats";
import ProjectCard from "../../../../component/ProjectCard";

export default function MyContributions() {
  const [updater, setUpdater] = useState({
    page: 0,
    pageSize: 30,
    loading: true,
  });
  const stats = usePoolListUser(updater);
  return (
    <div className="container">
      <React.Fragment>
        <section className="project-area explore-area">
          <div className="intro">
            <div className="text-center intro-content">
              <span className="intro-text">Presale List</span>
            </div>
            <div
              className="flex-wrap pl-3 mt-5 explore-menu btn-group btn-group-toggle"
              data-toggle="buttons"
            >
              
              <label className={`p-2 btn d-table text-uppercase`}>
                <Link to="/active-list" className="explore-btn">
                  <span>Active</span>
                </Link>
              </label>
              <label className={`p-2 btn d-table text-uppercase`}>
                <Link to="/upcoming-list" className="explore-btn">
                  <span>Upcoming</span>
                </Link>
              </label>
              <label className={`p-2 btn d-table text-uppercase`}>
                <Link to="/completed-list" className="explore-btn">
                  <span>Completed</span>
                </Link>
              </label>
              <label className={`p-2 btn d-table text-uppercase`}>
                <Link to="/cancelled-list" className="explore-btn">
                  <span>Cancelled</span>
                </Link>
              </label>
              <label className={`p-2 btn d-table text-uppercase`}>
                <Link to="/ended-list" className="explore-btn">
                  <span>Ended</span>
                </Link>
              </label>
              <label className="p-2 btn d-table text-uppercase active">
                <Link to="/my-contribution" className="explore-btn">
                  <span>My Contributions</span>
                </Link>
              </label>
            </div>
          </div>

          <div className="row items">
            <React.Fragment>
              {stats.loading ? (
                <div className="col-md-12">
                  <HashLoader
                    size="100"
                    color="#fff"
                    cssOverride={{
                      left: "50%",
                      textAlign: "center",
                      top: "50%",
                    }}
                  />
                </div>
              ) : stats.poolList.length > 0 ? (
                stats.poolList
                  .slice(0)
                  .reverse()
                  .map((rowdata, index) => {
                    if (rowdata.poolType !== "1")
                      return <ProjectCard
                      chainId={stats.chainId}
                      rowdata={rowdata}
                      index={index} 
                      key={index}/>
                  })
              ) : (
                <div className="mt-5 col-12 item explore-item">
                  <p className="text-center">No Presale Found</p>
                </div>
              )}
            </React.Fragment>
          </div>
          <div className="mt-4 row mt-md-5">
            <div className="col-12">
              <nav>
                <ul className="page-numbers">
                  {stats.page > 0 && (
                    <li>
                      <a
                        className="next page-numbers"
                        href="#sec"
                        onClick={(e) =>
                          setUpdater({
                            page: stats.page - 1,
                            pageSize: stats.pageSize,
                            loading: true,
                          })
                        }
                      >
                        <i className="icon-arrow-left"></i>Previous
                      </a>
                    </li>
                  )}
                  {Math.floor(stats.getTotalNumberOfPools / stats.pageSize) >
                    stats.page && (
                    <li>
                      <a
                        className="next page-numbers"
                        href="#sec"
                        onClick={(e) =>
                          setUpdater({
                            page: stats.page + 1,
                            pageSize: stats.pageSize,
                            loading: true,
                          })
                        }
                      >
                        Next
                        <i className="icon-arrow-right"></i>
                      </a>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
          </div>
        </section>
      </React.Fragment>
    </div>
  );
}
