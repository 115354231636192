import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import Web3 from "web3";
import "../launchpadApply/index.css";
import Context from "./context/Context";

export default function TokenDetails(props) {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const web3 = new Web3(window.ethereum);
  
  const [chainId, setChainId] = React.useState(null);

  React.useEffect(() => {
    const getChainId = async () => {
      const id = await web3.eth.getChainId();
      setChainId(id);
    };
    getChainId();
  }, []);

  const getExplorerLink = (chainId) => {
    switch (chainId) {
      case 24116:
        return `https://build.steamexchange.io/token/${params.get("addr")}`;
      case 6278:
        return `https://explore.steamexchange.io/token/${params.get("addr")}`;
      default:
        return `#`; // Fallback URL in case the chain ID doesn't match
    }
  };

  return (
    <Context.Provider>
      <React.Fragment>
        <section className="explore-area create-token">
          <div className="container px-3">
            <div className="intro">
              <div className="text-center intro-content">
                <span className="intro-text">Create a Token</span>
              </div>
            </div>
            <div className="my-5">
              <div className="card stepcard">
                <div className="card-body">
                  <h5 className="text-center">
                    Token generation was successful.
                  </h5>
                  <h4 className="my-5 text-center text-uppercase">
                    ADDRESS: <span className="address-text">{params.get("addr")}</span>
                  </h4>

                  <style jsx>{`
                    .address-text {
                      text-transform: none;
                    }
                  `}</style>

                  <div className="d-flex justify-content-center">
                    <button className="btn-negative">
                      <a
                        className="text-light"
                        href={getExplorerLink(chainId)}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        View on Rails Network explorer
                      </a>
                    </button>
                    <button className="btn-negative">
                      <Link className="text-light" to="/presale">
                        Create Presale
                      </Link>
                    </button>
                    {/* <button className="btn">
                      <Link className="text-dark" to="/fairlaunch">
                        Create Fairlaunch
                      </Link>
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    </Context.Provider>
  );
}
