import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import HashLoader from "react-spinners/HashLoader";
import { usePoolListStats } from "./helper/useStats";
import ProjectCard from "../../../component/ProjectCard";
import { ethers } from "ethers";
import { getSubgraphUrl } from "../../../hooks/network";

export default function ProjectList({ filter }) {
  const [updater, setUpdater] = useState({
    page: 0,
    pageSize: 30,
    loading: true,
  });

  const [chainId, setChainId] = useState(null); 
  const [subgraphUrl, setSubgraphUrl] = useState(null); 
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredPools, setFilteredPools] = useState([]);

  // Fetch the chain ID from the connected wallet
  useEffect(() => {
    const fetchChainId = async () => {
      if (window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const network = await provider.getNetwork();
        const detectedChainId = network.chainId;
        setChainId(detectedChainId);
        const url = getSubgraphUrl(detectedChainId);
        setSubgraphUrl(url);  
        setUpdater(prev => ({ ...prev, loading: true }));
        console.log("Detected Chain ID in ProjectList:", detectedChainId);
        console.log("Subgraph URL in ProjectList:", url);
      } else {
        console.error("No Ethereum provider found. Install MetaMask.");
      }
    };

    fetchChainId();  

    if (window.ethereum) {
      window.ethereum.on('chainChanged', fetchChainId); 
    }

    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener('chainChanged', fetchChainId);
      }
    };
  }, []);

  
  const stats = usePoolListStats(updater, chainId, subgraphUrl);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    if (!stats.loading) {
      const pools = stats.poolList.filter((rowdata) => {
        if (rowdata.poolType !== "1") {
          const currentTime = Math.floor(new Date().getTime() / 1000.0);
          return (
            (filter === "active" && parseInt(rowdata.startTime) < currentTime && parseInt(rowdata.endTime) > currentTime) ||
            (filter === "upcoming" && parseInt(rowdata.startTime) > currentTime) ||
            (filter === "ended" && rowdata.poolState !== "2" && rowdata.poolState !== "1" && parseInt(rowdata.endTime) < currentTime) ||
            (filter === "cancelled" && rowdata.poolState === "2") ||
            (filter === "completed" && rowdata.poolState === "1")
          );
        }
        return false;
      });
      setFilteredPools(pools);
    }
  }, [stats.loading, filter]);

  useEffect(() => {
    if (!stats.loading) {
      const filtered = stats.poolList.filter((pool) => {
        if (pool.poolType !== "1") {
          const currentTime = Math.floor(new Date().getTime() / 1000.0);
          const matchesFilter =
            (filter === "active" && parseInt(pool.startTime) < currentTime && parseInt(pool.endTime) > currentTime) ||
            (filter === "upcoming" && parseInt(pool.startTime) > currentTime) ||
            (filter === "ended" && pool.poolState !== "2" && pool.poolState !== "1" && parseInt(pool.endTime) < currentTime) ||
            (filter === "cancelled" && pool.poolState === "2") ||
            (filter === "completed" && pool.poolState === "1");

          const matchesSearchQuery = searchQuery
            ? pool.tokenName && pool.tokenName.includes(searchQuery)
            : true;

          return matchesFilter && matchesSearchQuery;
        }
        return false;
      });
      setFilteredPools(filtered);
    }
  }, [searchQuery, stats.poolList, filter, stats.loading]);

  return (
    <div className="container">
      <React.Fragment>
        <section className="project-area explore-area">
          <div className="intro">
            <div className="text-center intro-content">
              <span className="intro-text">Presale List</span>
            </div>
            <div className="flex-wrap pl-3 mt-5 explore-menu btn-group">
              <label className={`p-2 btn d-table text-uppercase ${filter === "active" ? "active" : ""}`}>
                <Link to="/active-list" className="explore-btn">
                  <span>Active</span>
                </Link>
              </label>
              <label className={`p-2 btn d-table text-uppercase ${filter === "upcoming" ? "active" : ""}`}>
                <Link to="/upcoming-list" className="explore-btn">
                  <span>Upcoming</span>
                </Link>
              </label>
              <label className={`p-2 btn d-table text-uppercase ${filter === "completed" ? "active" : ""}`}>
                <Link to="/completed-list" className="explore-btn">
                  <span>Completed</span>
                </Link>
              </label>
              <label className={`p-2 btn d-table text-uppercase ${filter === "cancelled" ? "active" : ""}`}>
                <Link to="/cancelled-list" className="explore-btn">
                  <span>Cancelled</span>
                </Link>
              </label>
              <label className={`p-2 btn d-table text-uppercase ${filter === "ended" ? "active" : ""}`}>
                <Link to="/ended-list" className="explore-btn">
                  <span>Ended</span>
                </Link>
              </label>
              <label className="p-2 btn d-table text-uppercase">
                <Link to="/my-contribution" className="explore-btn">
                  <span>My Contributions</span>
                </Link>
              </label>
            </div>
            <div className="mt-3 text-center">
              <input
                type="text"
                placeholder="Search by token name"
                value={searchQuery}
                onChange={handleSearchChange}
                style={{
                  padding: "10px",
                  width: "80%",
                  maxWidth: "600px",
                  margin: "0 auto",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                }}
              />
            </div>
          </div>

          <div className="row items">
            <React.Fragment>
              {stats.loading ? (
                <div className="col-md-12">
                  <HashLoader
                    size="100"
                    color="#fff"
                    cssOverride={{
                      left: "50%",
                      textAlign: "center",
                      top: "50%",
                    }}
                  />
                </div>
              ) : filteredPools.length > 0 ? (
                filteredPools
                  .slice(0)
                  .reverse()
                  .map((rowdata, index) => (
                    <ProjectCard
                      chainId={chainId}
                      rowdata={rowdata}
                      index={index}
                      key={index}
                    />
                  ))
              ) : (
                <div className="mt-5 col-12 item explore-item">
                  <p className="text-center">No Presales Found</p>
                </div>
              )}
            </React.Fragment>
          </div>
          <div className="mt-4 row mt-md-5">
            <div className="col-12">
              <nav>
                <ul className="page-numbers">
                  {stats.page > 0 && (
                    <li>
                      <a
                        className="next page-numbers"
                        href="#sec"
                        onClick={(e) =>
                          setUpdater({
                            page: stats.page - 1,
                            pageSize: stats.pageSize,
                            loading: true,
                          })
                        }
                      >
                        <i className="icon-arrow-left"></i>Previous
                      </a>
                    </li>
                  )}
                  {Math.floor(stats.getTotalNumberOfPools / stats.pageSize) > stats.page && (
                    <li>
                      <a
                        className="next page-numbers"
                        href="#sec"
                        onClick={(e) =>
                          setUpdater({
                            page: stats.page + 1,
                            pageSize: stats.pageSize,
                            loading: true,
                          })
                        }
                      >
                        Next
                        <i className="icon-arrow-right"></i>
                      </a>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
          </div>
        </section>
      </React.Fragment>
    </div>
  );
}
