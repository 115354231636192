import React, { useState } from "react";
import HashLoader from "react-spinners/HashLoader";
import { Link } from "react-router-dom";
import { useCommonLpStats } from "./helper/useStats";
import { useNetwork } from "wagmi";

export default function LpLockList() {
  const [updater, setUpdater] = useState({
    page: 0,
    pageSize: 10,
    loading: true,
  });
  const stats = useCommonLpStats(updater);
  const chain = useNetwork();

  const host = window.location.host;
  const protocol = window.location.protocol;
  const explorerLink = `${protocol}//${host}/lock-details/`;

  function handleClick(location) {
    window.location.href = explorerLink + location;
  }

  return (
    <div className="container">
      <React.Fragment>
        <section className="explore-area prev-project-area">
          <div className="intro">
            <div className="text-center intro-content">
              <span className="intro-text">Locked LP Token List</span>
            </div>
            <div
              className="flex-wrap pl-3 mt-5 d-flex explore-menu"
              data-toggle="buttons"
            >
              <label className="p-2 btn active d-table text-uppercase">
                <Link to="/liquidity-locked" className="explore-btn">
                  <span>Locked Pools</span>
                </Link>
              </label>
              <label className="p-2 btn d-table text-uppercase">
                <Link to="/my-lp-lock" className="explore-btn">
                  <span>My Locked Pools</span>
                </Link>
              </label>
            </div>
          </div>

          <section className="pt-2 card leaderboard-area">
            <div className="row">
              <div className="col-12">
                <div className="table-responsive">
                  <table className="table table-borderless">
                    <thead>
                      <tr>
                        <th scope="col">No</th>
                        <th scope="col">Name</th>
                        <th scope="col">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {stats.loading ? (
                        <div className="row">
                          <div className="col-12 d-flex justify-content-between">
                            <HashLoader
                              size="100"
                              color="#fff"
                              cssOverride={{ left: "50%", top: "20%" }}
                            />
                          </div>
                        </div>
                      ) : stats.tokenList.length > 0 ? (
                        stats.tokenList
                          .slice(0)
                          .reverse()
                          .map((rowdata, index) => {
                            return (
                              <tr
                                key={index}
                                onClick={() => handleClick(rowdata.token)}
                                style={{ cursor: "pointer" }}
                              >
                                <td>
                                  {stats.page > 0
                                    ? stats.page * stats.pageSize + 1 + index
                                    : index + 1}
                                </td>
                                <td className="image-row"> {rowdata.name}</td>
                                <td>
                                  {rowdata.amount
                                    ? rowdata.amount /
                                      Math.pow(10, rowdata.decimals)
                                    : "0"}{" "}
                                  {rowdata.symbol}
                                </td>
                              </tr>
                            );
                          })
                      ) : (
                        <tr className="mt-4 text-center">
                          <td style={{ backgroundColor: "transparent" }}>
                            No Lock Record Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="mt-3 row">
              <div className="col-12">
                <nav>
                  <ul className="page-numbers">
                    <li>
                      {stats.page > 0 && (
                        <a
                          href="#sec"
                          onClick={(e) =>
                            setUpdater({
                              page: stats.page - 1,
                              pageSize: stats.pageSize,
                              loading: true,
                            })
                          }
                        >
                          <i className="mr-2 icon-Vector"></i>Previous
                        </a>
                      )}
                    </li>
                    {Math.floor(
                      stats.allNormalTokenLockedCount / stats.pageSize
                    ) > parseFloat(stats.page) && (
                      <li>
                        <a
                          href="#sec"
                          onClick={(e) =>
                            setUpdater({
                              page: stats.page + 1,
                              pageSize: stats.pageSize,
                              loading: true,
                            })
                          }
                        >
                          Next<i className="ml-2 icon-arrow_right"></i>
                        </a>
                      </li>
                    )}
                  </ul>
                </nav>
              </div>
            </div>
          </section>
        </section>
      </React.Fragment>
    </div>
  );
}
