import { useState, useMemo, useEffect } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import {
  FaRocket,
  FaShieldAlt,
  FaLock,
  FaDochub,
  FaTelegramPlane,
  FaTwitter,
  FaHome,
  FaNetworkWired
} from "react-icons/fa";
import { SiAdobeaudition } from "react-icons/si";
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarHeader,
  SubMenu,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import styled from "styled-components";
import queryString from "query-string";

const Menuitem = styled(MenuItem)`
  :hover {
    background-color: #ffdb58;
    padding: 5px;
    border-radius: 10px;
  }
`;

export function useRouter() {
  const params = useParams();
  const location = useLocation();

  return useMemo(() => {
    return {
      push: location.push,
      replace: location.replace,
      pathname: location.pathname,
      query: {
        ...queryString.parse(location.search), // Convert string to object
        ...params,
      },
      location,
    };
  }, [params, location]);
}

const MainLayout = (props) => {
  const router = useRouter();

  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 575;
  const [collapsed, setCollapsed] = useState(isMobile);

  const onClickMenuIcon = () => {
    setCollapsed(!collapsed);
  };

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const onClickMenuItem = () => {
    if (isMobile) setCollapsed(true);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <>
      <ProSidebar collapsed={collapsed}>
        <SidebarHeader>
          {!collapsed ? (
            <img
              src={require("../images/logo-text.png")}
              alt="Brand Logo"
              width="250px"
              style={{ paddingBottom: "30px" }} // Adjust this value as needed
            />
          ) : (
            <img
              src={require("../images/logo-only.png")}
              alt="Brand Logo"
              width="100px"
              style={{ padding: "10px" }} // Adjust this value as needed
            />
          )}
          <div className="toggleMenu hide-on-mobile" onClick={onClickMenuIcon}>
            <AiOutlineMenu />
          </div>
        </SidebarHeader>
        <Menu iconShape="square">
          <Menuitem
            icon={<FaHome />}
            className={`${router.pathname === "/" ? "active" : ""}`}
          >
            <Link to="/" onClick={onClickMenuItem}>
              Home
            </Link>
          </Menuitem>
          <SubMenu
            title="Presale Management"
            icon={<FaRocket />}
            className={`${
              router.pathname === "/presale" ||
              router.pathname === "/token" ||
              router.pathname === "/sale-list" ||
              router.pathname === "/active-list" ||
              router.pathname === "/upcoming-list" ||
              router.pathname === "/ended-list" ||
              router.pathname === "/completed-list" ||
              router.pathname === "/cancelled-list" ||
              router.pathname === "/my-contribution"
                ? "active"
                : ""
            }`}
          >
            <MenuItem
              className={`${router.pathname === "/token" ? "active" : ""}`}
            >
              <Link to="/token" onClick={onClickMenuItem}>
                Create a Token
              </Link>
            </MenuItem>
            <Menuitem
              className={`${router.pathname === "/presale" ? "active" : ""}`}
            >
              <Link to="/presale" onClick={onClickMenuItem}>
                Create a Presale
              </Link>
            </Menuitem>
            
            <MenuItem
              className={`${
                router.pathname === "/sale-list" ||
                router.pathname === "/active-list" ||
                router.pathname === "/upcoming-list" ||
                router.pathname === "/ended-list" ||
                router.pathname === "/completed-list" ||
                router.pathname === "/cancelled-list" ||
                router.pathname === "/my-contribution"
                  ? "active"
                  : ""
              }`}
            >
              <Link to="/active-list" onClick={onClickMenuItem}>
                Presale List
              </Link>
            </MenuItem>
          </SubMenu>
          <SubMenu
            title="Rails: Locker"
            icon={<FaLock />}
            className={`${
              router.pathname === "/lock" ||
              router.pathname === "/token-locked" || router.pathname === "/my-token-lock" ||
              router.pathname === "/liquidity-locked" || router.pathname === "/my-lp-lock" 
                ? "active"
                : ""
            }`}
          >
            <Menuitem
              className={`${router.pathname === "/lock" ? "active" : ""}`}
            >
              <Link to="/lock" onClick={onClickMenuItem}>
                Create Lock
              </Link>
            </Menuitem>
            <MenuItem
              className={`${
                router.pathname === "/token-locked" || router.pathname === "/my-token-lock" ? "active" : ""
              }`}
            >
              <Link to="/token-locked" onClick={onClickMenuItem}>
                Token
              </Link>
            </MenuItem>
            <MenuItem
              className={`${
                router.pathname === "/liquidity-locked" || router.pathname === "/my-lp-lock" ? "active" : ""
              }`}
            >
              <Link to="/liquidity-locked" onClick={onClickMenuItem}>
                Liquidity
              </Link>
            </MenuItem>
          </SubMenu>
          <Menuitem icon={<FaDochub />}>
            <a href="https://docs.steamexchange.io/" target="_blank" rel="noopener noreferrer">
              Docs
            </a>
          </Menuitem>
          <Menuitem icon={<FaTelegramPlane />}>
            <a href="https://t.me/SteamXchangeOfficial" target="_blank" rel="noopener noreferrer">
              Telegram
            </a>
          </Menuitem>
          <Menuitem icon={<FaTwitter />}>
            <a href="https://twitter.com/steam_exchange" target="_blank" rel="noopener noreferrer">
              Twitter
            </a>
          </Menuitem>
        </Menu>
      </ProSidebar>
      {collapsed && (
        <div
          className="mobile-toggleMenu show-on-mobile"
          onClick={onClickMenuIcon}
        >
          <AiOutlineMenu />
        </div>
      )}
      {!collapsed && isMobile && (
        <div
          className="mobile-closeMenu show-on-mobile"
          onClick={() => setCollapsed(true)}
          style={{ position: "fixed", top: "10px", right: "10px", zIndex: 9000 }}
        >
          <AiOutlineClose />
        </div>
      )}
    </>
  );
};

export default MainLayout;
