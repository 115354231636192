import React, { useState, useContext, useEffect } from "react";
import "../launchpadApply/index.css";
import StandardToken from "./StandardToken";
import BabyToken from "./BabyToken";
import BuyBackBabyToken from "./BuyBackBabyToken";
import LiquidityToken from "./LiquidityToken";
import Context from "./context/Context";
import { defaultValue } from "./context/defaults";
import { useAccount, useNetwork } from "wagmi";
import { BigNumber, ethers } from "ethers";

export default function MainToken() {
  const appContext = useContext(Context);
  const [context, setContext] = useState(appContext);
  const chain = useNetwork();
  const address = useAccount();
  const [type, setType] = useState("1");
  const [createFee, setCreateFee] = useState(BigNumber.from(0)); // Start with 0
  const [tokenAmount, setTokenAmount] = useState("0");

  useEffect(() => {
    if (!address) {
      setContext({ ...context, value: { ...defaultValue } });
    }
    // eslint-disable-next-line
  }, [chain?.id, address]);

  useEffect(() => {
    const fetchTokenPriceAndCalculateFee = async () => {
      try {
        const response = await fetch(
          'https://api.geckoterminal.com/api/v2/simple/networks/rails-network/token_price/0x0000000000000000000000000000000000627800',
          {
            headers: {
              accept: "application/json",
            },
          }
        );
        const data = await response.json();

        // Extract the price using the correct path in the response
        const price = parseFloat(data.data.attributes.token_prices["0x0000000000000000000000000000000000627800"]);

        if (isNaN(price) || price <= 0) {
          throw new Error("Invalid token price received from the API");
        }

        const usdAmount = 150; // Fee in USD
        const feeInTokens = usdAmount / price; // Calculate how many tokens equal $150
        const feeInWeiString = (feeInTokens * 10 ** 18).toLocaleString('fullwide', { useGrouping: false }); // Convert to full string
        const feeInWei = BigNumber.from(feeInWeiString);

        setCreateFee(feeInWei);
        setTokenAmount(feeInTokens.toFixed(4)); // Display token amount with 4 decimal places
      } catch (error) {
        console.error("Error fetching token price:", error);
        // Fallback to default if there's an error
        const defaultFee = BigNumber.from("25000").mul(BigNumber.from("10").pow(18));
        setCreateFee(defaultFee);
        setTokenAmount("25000"); // Fallback token amount
      }
    };

    fetchTokenPriceAndCalculateFee();
  }, []);

  const state = {
    ...context,
    setValue: (value) => setContext({ ...context, value }),
  };

  const onChangeType = (e) => {
    setType(e.target.value);
  };

  return (
    <Context.Provider value={state}>
      <React.Fragment>
        <section className="explore-area create-token">
          <div className="container px-3">
            <div className="intro">
              <div className="text-center intro-content">
                <span className="intro-text">Create a Token</span>
              </div>
            </div>
            <div className="my-5">
              <div className="card stepcard">
                <div className="card-body">
                  <form className="login-box">
                    <div className="">
                      <div
                        className={`tab-pane active mt-3`}
                        role="tabpanel"
                        id="step1"
                      >
                        <div className="row">
                          <div className="mb-0 col-12">
                            <label>
                              Token Type<span className="text-danger">*</span>
                            </label>
                            <select
                              className="form-control"
                              onChange={(e) => onChangeType(e)}
                            >
                              <option value="1">Standard Token</option>
                              <option value="4">Liquidity (tax) Token</option>
                            </select>
                            <small>Fee: {tokenAmount} STEAMX ($150)</small>
                          </div>
                        </div>
                      </div>
                    </div>
                    {type === "1" && <StandardToken createFee={createFee} />}
                    {type === "2" && <BabyToken createFee={createFee} />}
                    {type === "3" && <BuyBackBabyToken createFee={createFee} />}
                    {type === "4" && <LiquidityToken createFee={createFee} />}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    </Context.Provider>
  );
}
