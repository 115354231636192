import React from "react";
import { trimAddress } from "../../hooks/constant";
import { formatPrice } from "../../hooks/contractHelper";
import { useDetailsStats } from "./helper/useStats";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useNetwork } from "wagmi";

export default function LockView() {
  const stats = useDetailsStats(1);
  const chain = useNetwork();
  const search = useLocation().search;
  const queryChainId = new URLSearchParams(search).get("chainid");

  const explorerLink = "https://build.steamexchange.io/address/";

  const host = window.location.host;
  const protocol = window.location.protocol;
  const detailedLink = `${protocol}//${host}/lock-record/`;

  function handleClick(location) {
    window.location.href = explorerLink + location;
  }

  function handleItemClick(location) {
    window.location.href = detailedLink + location;
  }

  return (
    <div className="container detail-page">
      <React.Fragment>
        <section className="explore-area prev-project-area">
          <div className="mt-5 igo-rankging-table-list">
            <div className="m-3 card project-card">
              <h4 className="ml-3 card-title">Lock info</h4>
              <div className="card-body">
                <div className="mt-3 d-flex justify-content-between flex-column flex-sm-row card-span">
                  <span>Current Locked Amount</span>
                  <p className="step-input-value">
                    {formatPrice(stats.cumulativeLockInfo)} {stats.TokenSymbol}
                  </p>
                </div>
                <div className="mt-3 d-flex justify-content-between flex-column flex-sm-row card-span">
                  <span>Token Address </span>
                  <a className="step-input-value" href={explorerLink + stats.TokenAddress} target="_blank" rel="noreferrer noopener" style={{ cursor: "pointer" }}>{stats.TokenAddress}</a>
                </div>
                <div className="mt-3 d-flex justify-content-between flex-column flex-sm-row card-span">
                  <span>Token Name</span>
                  <p className="step-input-value">{stats.TokenName}</p>
                </div>
                <div className="mt-3 d-flex justify-content-between flex-column flex-sm-row card-span">
                  <span>Token Symbol</span>
                  <p className="step-input-value">{stats.TokenSymbol}</p>
                </div>
                <div className="mt-3 d-flex justify-content-between flex-column flex-sm-row card-span">
                  <span>Token Decimals</span>
                  <p className="step-input-value">{stats.TokenDecimals}</p>
                </div>
              </div>
            </div>
            <div className="p-2 m-3 card project-card">
              <h4 className="ml-3 card-title">Lock records</h4>

              <div className="table-responsive">
                <table
                  className="table table-borderless"
                  cellSpacing="0"
                  cellPadding="0"
                  style={{ border: "none", color: "#fff" }}
                >
                  <thead>
                    <tr>
                      <td>Wallet</td>
                      <td>Amount</td>
                      <td>Cycle(d)</td>
                      <td>Cycle Release(%)</td>
                      <td>TGE(%)</td>
                      <td>Unlock time(UTC)</td>
                    </tr>
                  </thead>
                  <tbody>
                    {stats.lockdata.map((rowdata, index) => {
                      return (
                        <tr key={index} onClick={() => handleItemClick(rowdata.id)} style={{ cursor: "pointer" }}>
                          <td>{trimAddress(rowdata.owner)}</td>
                          <td>
                            {rowdata.amount / Math.pow(10, stats.TokenDecimals)}
                          </td>
                          <td>
                            {rowdata.cycle > 0 ? rowdata.cycle / 60 : "-"}
                          </td>
                          <td>
                            {rowdata.cycleBps > 0
                              ? rowdata.cycleBps / 100
                              : "-"}
                          </td>
                          <td>
                            {rowdata.tgeBps > 0 ? rowdata.tgeBps / 100 : "-"}
                          </td>
                          <td style={{ fontSize: "12px" }}>
                            {rowdata.tgeDate
                              ? new Date(rowdata.tgeDate * 1000)
                                  .toUTCString()
                                  .substring(4, 25)
                              : "-"}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    </div>
  );
}
