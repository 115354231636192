import React, { useState, useContext, useEffect } from "react";
import "./index.css";
import Step1 from "./presale/Step1";
import Step2 from "./presale/Step2";
import Step3 from "./presale/Step3";
// import Step4 from "./presale/Step4.js_";
import Step4 from "./presale/Step4";
import Context from "./presale/context/Context";
// import { useWeb3React } from "@web3-react/core";
import { defaultValue } from "./presale/context/defaults";
import { useNetwork, useAccount } from "wagmi";
export default function PreSale() {
  const appContext = useContext(Context);
  const [context, setContext] = useState(appContext);
  // const { chainId, account } = useWeb3React();
  const { chain } = useNetwork()
  const { address } = useAccount()

  const setValue = (value) => {
    setContext({ ...context, value });
  };

  useEffect(() => {
    if (!address)
      setContext({ ...context, value: { ...defaultValue } });
    // eslint-disable-next-line
  }, [chain?.id, address]);

  const btnNextStep = (e) => {
    if (context.value.step < context.value.maxStep) {
      setValue({ ...context.value, step: parseInt(context.value.step + 1) });
    }
  };

  const btnPrevStep = (e) => {
    if (context.value.step > 1) {
      setValue({ ...context.value, step: parseInt(context.value.step - 1) });
    }
  };

  const state = {
    ...context,
    setValue: setValue,
    btnNextStep: btnNextStep,
    btnPrevStep: btnPrevStep,
  };

  return (
    <Context.Provider value={state}>
      <React.Fragment>
        <section className="explore-area prev-project-area">
          <div className="container px-3">
            <div className="intro">
              <div className="text-center intro-content">
                <span className="intro-text">Create Launchpad</span>
              </div>
            </div>
            <div className="my-5 signup-step-container">
              <div className="wizard">
                <div className="wizard-inner">
                  <div className="connecting-line"></div>
                  <ul className="nav nav-tabs" role="tablist">
                    <li
                      role="presentation"
                      className={`${context.value.step === 1 ? "active" : "disabled"
                        }`}
                    >
                      <a href="#step1">
                        <span className="round-tab">1 </span>{" "}
                        <i>Verify Token Address</i>
                      </a>
                    </li>
                    <li
                      role="presentation"
                      className={`${context.value.step === 2 ? "active" : "disabled"
                        }`}
                    >
                      <a href="#step2">
                        <span className="round-tab">2</span>{" "}
                        <i>Presale Information</i>
                      </a>
                    </li>
                    <li
                      role="presentation"
                      className={`${context.value.step === 3 ? "active" : "disabled"
                        }`}
                    >
                      <a href="#step3">
                        <span className="round-tab">3</span>{" "}
                        <i>Additional Information</i>
                      </a>
                    </li>
                    {/* <li
                      role="presentation"
                      className={`${
                        context.value.step === 4 ? "active" : "disabled"
                      }`}
                    >
                      <a href="#step4">
                        <span className="round-tab">4</span>{" "}
                        <i>Marketing Info</i>
                      </a>
                    </li> */}
                    <li
                      role="presentation"
                      className={`${context.value.step === 4 ? "active" : "disabled"
                        }`}
                    >
                      <a href="#step3">
                        <span className="round-tab">4</span> <i>Finalize</i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="pt-5 mt-5 card stepcard">
                <div className="card-body">
                  <form className="login-box">
                    <div className="tab-content" id="main_form">
                      <Step1 />
                      <Step2 />
                      <Step3 />
                      {/* <Step4 /> */}
                      <Step4 />
                    </div>
                  </form>
                </div>
              </div>
              <div className="mt-4 text-white">
                Disclaimer: The information provided does not constitute a recommendation for investing in any product discussed. Rails Network and Steam Exchange accept no liability for any loss incurred by any person acting or refraining from action based on the material provided or published.
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    </Context.Provider>
  );
}
